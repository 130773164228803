import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IAlert } from '../models/interface/IAlert';
import { Observable, map } from 'rxjs';
import { ISite } from '../models/interface/ISite';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private _httpService: HttpService) { }

  getActiveAlert(): Observable<IAlert> {
    return this._httpService.sendGetAsync<IAlert>(`site/alert`)
      .pipe(
        map((res) => res.body)
      );
  }
}
